.header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & h1 {
        font-weight: inherit;
        font-size: calc(max(10px + 6vmin, 1.2em));
    }

    & .logo {
        height: calc(max(40px + 36vmin, 1.2em));
    }
}
