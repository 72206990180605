.container {
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.container > div {
    flex-grow: 1;
}
.players {
    max-width: 400px;
}
.heading {
    margin: 16px 0;
}

.communityCards,
.hand {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.hand {
    margin: 4px 0;
}
.roundCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 2px solid color-mix(in srgb, var(--token-colour) 90%, white);
    /* background-color: color-mix(in srgb, var(--token-colour) 80%, transparent); */
    padding: 8px;
    border-radius: 8px;
    margin: 8px 0;
    &.futureRound {
        border-style: dashed;
    }
}

.handScore {
    visibility: visible;
}

.card,
.noCard {
    display: inline-block;
    box-sizing: border-box;
    width: 36px;
    height: 48px;
    padding: 8px 6px;
    border-radius: 8px;
    /* i do not understand css */
    vertical-align: top;
}
.noCard {
    background-color: rgba(255, 255, 255, 0.1);
}
.card {
    background-color: #fff;
    color: #000;

    font-size: 20px;
    text-align: center;
    line-height: 15px;
}
.card::first-line {
    font-size: 18px;
}
.cardJoker {
    font-size: 28px;
    line-height: 28px;
}
.redSuit {
    color: #e00;
}
.blackSuit {
    color: #000;
}
.cardHighlight {
    background-color: rgb(215, 240, 255);
    outline: 2px solid black;
    z-index: 1;
}
.cardClickable {
    box-shadow: 0 0 2px 2px lightblue;
    cursor: pointer;
}

.cardStack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
}

.player {
    margin: 4px;
    padding: 12px;
    border-radius: 8px;
}

.highlightPlayer {
    background: rgba(255, 255, 255, 0.1);
}

.playerName {
    font-weight: bold;
}

.tokenPool {
    margin: 4px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.token,
.noToken {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    display: inline-block !important;
    box-sizing: border-box;
    font: inherit;
    font-size: 22px;
    font-weight: 550;
    text-align: center;
    /* i do not understand css */
    vertical-align: top;
}
.noToken {
    border: 2px dashed white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.token1 {
    --token-colour: hsl(163, 65%, 33%);
}
.token2 {
    --token-colour: hsl(82, 94%, 28%);
}
.token3 {
    --token-colour: hsl(28, 89%, 35%);
}
.token4 {
    --token-colour: hsl(349, 94%, 35%);
}
.token {
    margin: -2px;

    border: none;
    background: url(./token.svg), var(--token-colour);
    color: black;
    padding: 0;
}
.token:hover:not(:disabled) {
    background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(./token.svg), var(--token-colour);
}
.token:active:not(:disabled) {
    background: radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(./token.svg), var(--token-colour);
}
.token.pastToken {
    visibility: visible;
}

.smallToken {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid white;
    text-align: center;
}

.revealLog {
    display: flex;
    flex-direction: column;
    margin: 4px 0;
}
.gameResult {
    visibility: visible;
}

.log {
    display: flex;
    flex-direction: column-reverse;
}

.log h2 {
    order: 1;
}

.roundLog {
    display: flex;
    flex-direction: column-reverse;
}

.roundLogEntry {
    visibility: visible;
}

.winRecord {
    display: flex;
    flex-direction: row;
    gap: 4px;

    & .win,
    & .notWin {
        width: 20px;
        height: 20px;
        border-radius: 12px;
        box-sizing: border-box;
    }
    & .win {
        border: 4px solid lightgreen;
    }
    & .notWin {
        border: 4px solid rgba(0, 0, 0, 0.3);
    }

    & .loss,
    & .notLoss {
        width: 20px;
        height: 20px;
        font-size: 36px;
        line-height: 14px;
        font-weight: bold;
        text-align: center;
    }
    & .loss::after,
    & .notLoss::after {
        content: "×";
    }
    & .loss {
        color: red;
    }
    & .notLoss {
        color: rgba(0, 0, 0, 0.3);
    }
}
